import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";

import { ReactComponent as AddIcon } from "@ntbjs/react-components/icons/add-circle.svg";
import { Button } from "@ntbjs/react-components/inputs";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { isEmpty, isEqual, map } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import qs from "qs";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { useQueryState } from "react-router-use-location-state";
import { useWindowSize } from "usehooks-ts";

import { ReactComponent as CloseIcon } from "../../assets/icons/clear-alt.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as VerticalEllipsisIcon } from "../../assets/icons/vertical-ellipsis.svg";
import style from "../../assets/scss/components/article/article-view.module.scss";
import styleCalendar from "../../assets/scss/components/calendar.module.scss";
import styleDashboard from "../../assets/scss/components/news-dashboard.module.scss";
import { hotkeys, useHotkey } from "../../helpers/hotkeys";
import {
  indexRoute,
  calendarRoute,
  newsSearchRoute,
  calendarSearchRoute
} from "../../helpers/routes";
import useDocumentTitle, { getDocumentTitle } from "../../helpers/useDocumentTitle";
import ArticleService from "../../services/ArticleService";
import CalendarService from "../../services/CalendarService";
import {
  selectArticleFilterIsMobile,
  selectArticleFilterById
} from "../../store/articleFilter/selector";
import {
  selectCalendarActiveNewsItemColumn,
  selectCalendarColumns
} from "../../store/calendar/selector";
import { setModalNewsItem as setCalendarModalNewsItem } from "../../store/calendar/slice";
import {
  selectDashboardColumnsShallow,
  selectDashboardActiveNewsItemColumn
} from "../../store/dashboard/selector";
import {
  setModalNewsItem as setDashboardModalNewsItem,
  showLatestArticleVersion as showDashboardLatestArticleVersion
} from "../../store/dashboard/slice";
import { selectUserArticleImagePlacementPreference } from "../../store/user/selector";
import Pulser from "../Pulser";
import Label from "../ui-element/Label";

import CopyToClipboard from "./actions/CopyToClipboard";
import Print from "./actions/Print";
import SendToEmail from "./actions/SendToEmail";
import ArticleViewSkeleton from "./ArticleViewSkeleton";
import Caption from "./Caption";
import MediaPreview from "./MediaPreview";
import Slider from "./Slider";

const articleService = new ArticleService();

const TEMPORARY_ATTRIBUTE = "data-temp-href-target";

DOMPurify.addHook("beforeSanitizeAttributes", function (node) {
  if (
    node.tagName === "A" &&
    node.hasAttribute("href") &&
    node.getAttribute("href").indexOf("mailto:") == -1
  ) {
    if (!node.hasAttribute("target")) {
      node.setAttribute("target", "_blank");
    }

    if (node.hasAttribute("target")) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute("target"));
    }
  }
});

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  if (node.tagName === "A" && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute("target", node.getAttribute(TEMPORARY_ATTRIBUTE));
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
    if (node.getAttribute("target") === "_blank") {
      node.setAttribute("rel", "noopener");
    }
  }
});

function extractLeadText(htmlString) {
  let contentMarkupDom = document.createElement("div");
  contentMarkupDom.innerHTML = htmlString;
  const matches = contentMarkupDom.getElementsByClassName("lead");

  return matches[0]?.innerHTML || null;
}

export function extractCalendarInfo(article) {
  if (!article || !article.contentMarkup) {
    return null;
  }

  // Create a temporary HTML element
  const tempElement = document.createElement("div");
  tempElement.innerHTML = article.contentMarkup;

  // Find the table with class "newsCalInfo"
  const tableElement = tempElement.querySelector(".newsCalInfo");

  // Initialize an empty object to store key-value pairs
  const resultObject = {};

  // If the table exists, iterate through its immediate rows and extract key-value pairs
  if (tableElement) {
    for (let row of tableElement.rows) {
      const cells = row.cells;
      if (cells.length === 2) {
        const key = cells[0].textContent.trim().replace(":", "").toLowerCase();
        const value = cells[1].innerHTML.trim(); // Use innerHTML to include HTML content
        resultObject[key] = value;
      }
    }
  }

  if (!isEmpty(resultObject["når"])) {
    const startDate = DateTime.fromMillis(article.startdate);
    const endDate = DateTime.fromMillis(article.stopdate);

    const isToBeConfirmedItem = article.realCalendarData?.timeToBeConfirmed || false;

    const startsAndEndsOnSameDate =
      startDate.startOf("day").toMillis() === endDate.startOf("day").toMillis();
    const fullDay =
      startDate.hour === 0 &&
      startDate.minute === 0 &&
      endDate.hour === 23 &&
      endDate.minute === 59;

    if (isToBeConfirmedItem) {
      if (startsAndEndsOnSameDate) {
        startDate.toFormat("dd.LL.yy");
      } else {
        startDate.toFormat("dd.LL.yy") + " - " + endDate.toFormat("dd.LL.yy");
      }
    } else if (startsAndEndsOnSameDate && fullDay) {
      resultObject["når"] = startDate.toFormat("dd.LL.yy – 'Hele dagen'");
    } else if (startsAndEndsOnSameDate) {
      resultObject["når"] =
        startDate.toFormat("dd.LL.yy 'kl.' HH.mm") + " - " + endDate.toFormat("HH.mm");
    } else if (!startsAndEndsOnSameDate && fullDay) {
      startDate.toFormat("dd.LL.yy") + " - " + endDate.toFormat("dd.LL.yy");
    } else {
      resultObject["når"] =
        startDate.toFormat("dd.LL.yy 'kl.' HH.mm") +
        " – " +
        endDate.toFormat("dd.LL.yy 'kl.' HH.mm");
    }
  }

  return resultObject;
}

export function decodeHtml(html) {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function removeUnwantedTitleStartWords(text) {
  // List of unwanted words
  const unwantedWords = ["Børsmelding:", "Pressemelding:"];

  // Iterate through unwanted words and remove matching prefixes
  unwantedWords.forEach(word => {
    if (text.startsWith(word)) {
      text = text.slice(word.length).trim();
    }
  });

  return text;
}

function ArticleView({ article, onClose, latestVersionId = null, modal = false, searchString }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useDocumentTitle(decodeHtml(article?.title), true);

  const isCalendarRoute = useMatch(calendarRoute);
  const isDashboardRoute = useMatch(indexRoute);
  const screen = useWindowSize();
  let [searchStringQuery] = useQueryState("searchString", "");

  const preferredArticleImagePlacement = useSelector(
    selectUserArticleImagePlacementPreference,
    isEqual
  );
  const isMobile = useSelector(selectArticleFilterIsMobile, isEqual);
  const dashboardColumns = useSelector(selectDashboardColumnsShallow, isEqual);
  const calendarColumns = useSelector(selectCalendarColumns);
  const dashBoardActiveNewsItemColumn = useSelector(selectDashboardActiveNewsItemColumn);
  const calendarActiveNewsItemColumn = useSelector(selectCalendarActiveNewsItemColumn);
  const articleFilter = useSelector(
    selectArticleFilterById(dashBoardActiveNewsItemColumn?.filterId)
  );

  const searchStringCollection = [];

  if (searchString) {
    searchStringCollection.push(searchString);
  }
  if (searchStringQuery) {
    searchStringCollection.push(searchStringQuery);
  }

  const articleFilterSearchString = articleFilter?.properties?.searchString;

  // if (articleFilterSearchString) {
  //   searchStringCollection.push(articleFilterSearchString);
  // }

  // searchStringQuery = "kjendisene OR danser";

  const articleViewRef = useRef(null);
  const articleTextViewRef = useRef(null);
  const articleScrollViewRef = useRef(null);
  const initialVersionsLimit = 4;
  const initialRelatedArticlesLimit = 5;
  const initialRelatedFactsLimit = 5;

  const [articleContent, setArticleContent] = useState(null);
  const [eventCoverages, setEventCoverages] = useState([]);
  const [eventAllCoverages, setEventAllCoverages] = useState([]);
  const [leadText, setLeadText] = useState(null);
  const [calendarInfo, setCalendarInfo] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [readTime, setReadTime] = useState(0);
  const [versions, setVersions] = useState([]);
  const [versionsLimit, setVersionsLimit] = useState(initialVersionsLimit);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [relatedArticlesLimit, setRelatedArticlesLimit] = useState(initialRelatedArticlesLimit);
  const [relatedFacts, setRelatedFacts] = useState([]);
  const [relatedFactsLimit, setRelatedFactsLimit] = useState(initialRelatedFactsLimit);
  const [newestVersionId, setNewestVersionId] = useState(null);
  const [previewingMedia, setPreviewingMedia] = useState(null);

  const handleArticleClose = () => {
    onClose();

    if (!modal) document.title = getDocumentTitle(null, pathname);
  };

  useHotkey(hotkeys.CLOSE_ARTICLE, handleArticleClose);

  const isCalendarEvent = useMemo(() => {
    return article?.service === "newscalendar";
  }, [article?.service]);

  const isToBeConfirmedItem = useMemo(() => {
    return article?.timeToBeConfirmed || article?.realCalendarData?.timeToBeConfirmed || false;
  }, [article?.realCalendarData?.timeToBeConfirmed, article?.timeToBeConfirmed]);

  const eventStartsAndEndsOnSameDate = useMemo(() => {
    if (!article?.endTime) {
      return true;
    }

    const startDate = DateTime.fromMillis(article.time);
    const endDate = DateTime.fromMillis(article.endTime);

    return startDate.startOf("day").toMillis() === endDate.startOf("day").toMillis();
  }, [article?.endTime, article?.time]);

  const eventIsFullDay = useMemo(() => {
    if (!article?.endTime) {
      return false;
    }

    const startDate = DateTime.fromMillis(article.time);
    const endDate = DateTime.fromMillis(article.endTime);
    return (
      startDate.hour === 0 && startDate.minute === 0 && endDate.hour === 23 && endDate.minute === 59
    );
  }, [article?.endTime, article?.time]);

  const setupEventCoverages = (coverages, eventDetails) => {
    if (coverages && Array.isArray(coverages)) {
      const todayStartDateMilis = DateTime.now().startOf("day").toMillis();
      const todayAndFutureCoverages = coverages.filter(
        coverage => coverage.planDate >= todayStartDateMilis
      );
      if (calendarActiveNewsItemColumn) {
        const columnStartDate = DateTime.fromSeconds(calendarActiveNewsItemColumn.startDate);
        const columnStartDateMilis = columnStartDate.startOf("day").toMillis();
        const columnEndDateMilis = columnStartDate.endOf("day").toMillis();
        const columnOnlyCoverages = coverages.filter(
          coverage =>
            coverage.planDate >= columnStartDateMilis && coverage.planDate <= columnEndDateMilis
        );

        if (columnOnlyCoverages.length == coverages.length) {
          setEventCoverages(coverages);
        } else if (columnOnlyCoverages.length > 0 && todayAndFutureCoverages.length == 0) {
          setEventCoverages(columnOnlyCoverages);
        } else {
          setEventCoverages(todayAndFutureCoverages);
        }
        setEventAllCoverages(coverages);
      } else {
        // const eventStartDate = DateTime.fromMillis(eventDetails.startdate);
        // const eventStopDate = DateTime.fromMillis(eventDetails.stopdate);

        if (todayAndFutureCoverages.length > 0) {
          setEventCoverages(todayAndFutureCoverages);
        } else {
          setEventCoverages(coverages);
        }
        setEventAllCoverages(coverages);
      }
    } else {
      setEventCoverages([]);
      setEventAllCoverages([]);
    }
  };

  useEffect(() => {
    if (!article) {
      onClose();
    }
  }, [article, onClose]);

  useEffect(() => {
    //  screen width 640 to 910
    if (
      !isMobile &&
      screen &&
      screen.width >= 640 &&
      // screen.width <= 910 &&
      ((isCalendarRoute && calendarColumns.length == 1) ||
        (isDashboardRoute && dashboardColumns.length == 1))
    ) {
      const computedStyle = window.getComputedStyle(articleScrollViewRef.current);
      const paddingLeftValue = parseFloat(computedStyle.getPropertyValue("padding-left"));
      const marginLeftValue = parseFloat(computedStyle.getPropertyValue("margin-left"));
      const scrollLeftValue =
        320 +
        marginLeftValue +
        paddingLeftValue +
        (articleTextViewRef.current?.offsetWidth || 0) -
        screen.width;

      if (articleViewRef.current) {
        setTimeout(() => {
          document
            .querySelector(
              "." +
                (isCalendarRoute
                  ? styleCalendar["calendar-container__calendar"]
                  : styleDashboard["dashboard"])
            )
            .scrollTo({
              behavior: "smooth",
              top: 0,
              left: scrollLeftValue
            });
          // articleTextViewRef.current &&
          //   articleTextViewRef.current.scrollIntoView({
          //     behavior: "smooth"
          //   });
        }, 1);
      }
    } else {
      if (articleViewRef.current) {
        setTimeout(() => {
          articleViewRef.current &&
            articleViewRef.current.scrollIntoView({
              behavior: "smooth"
            });
        }, 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    screen,
    isCalendarRoute,
    isDashboardRoute,
    calendarColumns,
    dashboardColumns,
    articleViewRef.current,
    articleTextViewRef.current,
    article
  ]);

  useEffect(() => {
    setNewestVersionId(latestVersionId);
  }, [latestVersionId]);

  const getArticle = article => {
    if (!article) {
      return;
    }
    setArticleContent(null);
    articleService
      .getOne(article.service, article.id, searchStringCollection.join(" OR "))
      .then(articleDetails => {
        // Replace the first \n from contentPlain
        let contentPlain = articleDetails.contentPlain.replace("\n", "").trim();
        const averageWordsReadPerMinute = 225;
        const words = contentPlain.replaceAll("\n", "").trim().split(/\s+/).length;
        const time = Math.ceil(words / averageWordsReadPerMinute);
        setWordCount(words);
        setReadTime(time);

        let contentMarkupDom = document.createElement("div");
        contentMarkupDom.innerHTML = articleDetails.contentMarkup;

        setCalendarInfo(extractCalendarInfo(articleDetails));
        const leadText = extractLeadText(articleDetails.contentMarkup);
        setLeadText(leadText);
        setArticleContent(articleDetails);
        setupEventCoverages(articleDetails.coverages || [], articleDetails);
      });
  };

  const getArticleVersions = article => {
    if (!article) {
      return;
    }
    setVersions([]);
    setVersionsLimit(initialVersionsLimit);
    if (!latestVersionId) setNewestVersionId(null);
    articleService.getVersions(article.service, article.ntbId).then(res => {
      setVersions(res);
      if (!latestVersionId && res.length > 1 && res[0].id !== article.id)
        setNewestVersionId(res[0].id);
    });
  };

  const getArticlesLikeThis = article => {
    setRelatedArticles([]);
    setRelatedFacts([]);
    setRelatedArticlesLimit(initialRelatedArticlesLimit);
    setRelatedFactsLimit(initialRelatedFactsLimit);
    if (!article) {
      return;
    }
    articleService.getMoreLikeThis(article.service, article.id).then(res => {
      setRelatedArticles(res.filter(item => !item.fact));
      setRelatedFacts(res.filter(item => item.fact));
    });
  };

  useEffect(() => {
    getArticle(article);
    getArticleVersions(article);
    getArticlesLikeThis(article);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const downloadCalendarEvent = useCallback(async () => {
    const calendarService = new CalendarService();
    await calendarService.downloadIcal(article.id);
  }, [article?.id]);

  const setModalNewsItem = useCallback(
    article => {
      if (isCalendarEvent) {
        dispatch(setCalendarModalNewsItem(article));
      } else {
        dispatch(setDashboardModalNewsItem(article));
      }
    },
    [isCalendarEvent, dispatch]
  );

  const handleVersionClick = version => {
    setModalNewsItem(version);

    ReactGA.event({
      category: "Article",
      action: "Click on other version of the article",
      label: version.title
    });
  };

  const handleRelatedArticleClick = relatedArticle => {
    setModalNewsItem(relatedArticle);

    ReactGA.event({
      category: "Article",
      action: "Click on related article",
      label: decodeHtml(relatedArticle.title)
    });
  };

  const handleRelatedFactClick = relatedFact => {
    setModalNewsItem(relatedFact);

    ReactGA.event({
      category: "Article",
      action: "Click on related fact",
      label: relatedFact.title
    });
  };

  const showLatestArticleVersion = useCallback(
    (id, versions, modal) => {
      if (!isCalendarEvent) dispatch(showDashboardLatestArticleVersion(id, versions, modal));
    },
    [isCalendarEvent, dispatch]
  );

  const previewMedia = useCallback(
    media => () => {
      setPreviewingMedia(media);

      ReactGA.event({
        category: "Article",
        action: "Click on image",
        label: media.url
      });
    },
    []
  );

  const doSearch = mediaTopicReference => {
    let queryObject = qs.parse(search, { ignoreQueryPrefix: true });
    queryObject.mediaTopics = mediaTopicReference;
    const searchRoute = isCalendarEvent ? calendarSearchRoute : newsSearchRoute;

    navigate({
      pathname: searchRoute,
      search: qs.stringify(queryObject)
    });

    ReactGA.event({
      category: isCalendarEvent ? "Search calendar" : "Search newsfeed",
      action: "Tag search",
      label: qs.stringify(queryObject)
    });

    // Keep the article opened until the search loader is shown
    // to prevent small UI flickering
    setTimeout(() => {
      onClose();
      document.title = getDocumentTitle(null, searchRoute);
    }, 10);
  };

  const mediaListByMediaType = useCallback(
    mediaType => {
      if (!articleContent?.mediaList) {
        return null;
      }

      return articleContent.mediaList.filter(m => m.mediaType === mediaType);
    },
    [articleContent?.mediaList]
  );

  const imageMedia = useMemo(() => {
    return mediaListByMediaType("image");
  }, [mediaListByMediaType]);

  const graphicsMedia = useMemo(() => {
    return mediaListByMediaType("grafikk") || [];
  }, [mediaListByMediaType]);

  const videoMedia = useMemo(() => {
    return mediaListByMediaType("video") || [];
  }, [mediaListByMediaType]);

  const otherMedia = useMemo(() => {
    return [...graphicsMedia, ...videoMedia];
  }, [graphicsMedia, videoMedia]);

  if (!article) {
    return null;
  }
  return (
    <div
      className={classNames(style["article-view"], {
        [style["article-view--with-notification"]]: newestVersionId
      })}
      ref={articleViewRef}
    >
      <div className={style["article-view__headline"]}>
        <div className={style["article-view__headline__datetime"]}>
          {isToBeConfirmedItem ? (
            <>{DateTime.fromMillis(article.time).toFormat("dd.MM.yyyy")}</>
          ) : (
            <>
              {eventStartsAndEndsOnSameDate ? (
                <>
                  {eventIsFullDay
                    ? DateTime.fromMillis(article.time).toFormat("dd.MM.yyyy, 'hele dagen'")
                    : DateTime.fromMillis(article.time).toFormat("dd.MM.yyyy, 'kl.' HH.mm")}
                </>
              ) : (
                <>
                  {DateTime.fromMillis(article.time).toFormat("dd.MM.yyyy")} –{" "}
                  {DateTime.fromMillis(article.endTime).toFormat("dd.MM.yyyy")}
                </>
              )}
            </>
          )}
        </div>
        <div className={style["article-view__headline__info"]}>ID: {article.id}</div>
        <div className={style["article-view__headline__actions"]}>
          {!isMobile && <Print article={article} />}
          <SendToEmail article={article} />

          {!isMobile && <CopyToClipboard article={articleContent} />}
          {/* <AddIcon /> */}

          {isCalendarEvent ? (
            <div
              className={style["article-view__headline__actions__download"]}
              onClick={downloadCalendarEvent}
            >
              <DownloadIcon />
              <Button size="small">{t("articleView:actions:downloadCalendarFile")}</Button>
            </div>
          ) : // <Button size="small">{t("articleView:actions:sendToPublishing")}</Button>
          null}
          <CloseIcon
            className={style["article-view__headline__actions__close"]}
            onClick={() => handleArticleClose()}
          />
        </div>
      </div>
      {newestVersionId && (
        <div className={style["article-view__notification"]}>
          <Pulser />
          <div className={style["article-view__notification__text"]}>
            {t("articleView:notification:newerVersionAvailable")}{" "}
            <a onClick={() => showLatestArticleVersion(newestVersionId, versions, modal)}>
              {t("articleView:notification:seeLatestVersion")}
            </a>
          </div>
        </div>
      )}
      <div className={style["article-view__scrollable"]} ref={articleScrollViewRef}>
        {articleContent ? (
          <>
            {eventCoverages && eventCoverages.length > 0 && (
              <div className={style["article-view__coverage-editorial-notes"]}>
                {eventCoverages.map((coverage, index) => {
                  let data = null;
                  switch (coverage.type) {
                    case "text":
                      data = {
                        icon: <MaterialSymbol icon="article" size={20} />,
                        defaultLabel: t("newsItem:calendarCoverages:text"),
                        cancelledLabel: t("newsItem:calendarCoverages:textCancelled")
                      };
                      break;
                    case "picture":
                      data = {
                        icon: <MaterialSymbol icon="photo" size={20} />,
                        defaultLabel: t("newsItem:calendarCoverages:pictures"),
                        cancelledLabel: t("newsItem:calendarCoverages:picturesCancelled")
                      };
                      break;
                    case "video":
                      data = {
                        icon: <MaterialSymbol icon="play_circle" size={20} />,
                        defaultLabel: t("newsItem:calendarCoverages:video"),
                        cancelledLabel: t("newsItem:calendarCoverages:videoCancelled")
                      };
                      break;
                    case "live_video":
                      data = {
                        icon: <MaterialSymbol icon="videocam" size={20} />,
                        defaultLabel: t("newsItem:calendarCoverages:liveVideo"),
                        cancelledLabel: t("newsItem:calendarCoverages:liveVideoCancelled")
                      };
                      break;
                    case "undecided_under_consideration":
                      data = {
                        icon: <MaterialSymbol icon="help" size={20} />,
                        defaultLabel: t("newsItem:calendarCoverages:underConsideration"),
                        cancelledLabel: null
                      };
                      break;
                  }

                  if (!data) {
                    return null;
                  }

                  const isCancelled = coverage.status === "cancelled";

                  return (
                    <div
                      className={style["article-view__coverage-editorial-notes__item"]}
                      key={index}
                    >
                      <div
                        className={classNames(
                          style["article-view__coverage-editorial-notes__item__icon"],
                          {
                            [style[
                              "article-view__coverage-editorial-notes__item__icon--cancelled"
                            ]]: isCancelled
                          }
                        )}
                        style={{ display: "flex" }}
                      >
                        {data.icon}
                        {isCancelled && (
                          <div
                            className={
                              style[
                                "article-view__coverage-editorial-notes__item__icon__cancelled-line"
                              ]
                            }
                          />
                        )}
                      </div>{" "}
                      <div style={{ paddingTop: "1px" }}>
                        {DateTime.fromMillis(coverage.planDate || coverage.time).toFormat(
                          "dd.LL.yy"
                        )}{" "}
                        –{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: isCancelled
                              ? coverage.cancelReason || data.cancelledLabel
                              : coverage.editorialNote
                              ? coverage.editorialNote
                              : data.defaultLabel
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div
              className={classNames(style["article-view__header"], {
                [style["article-view__header--no-media"]]:
                  preferredArticleImagePlacement !== "top" ||
                  !imageMedia ||
                  !imageMedia.length ||
                  (imageMedia && imageMedia.length > 1)
              })}
            >
              <div className={style["article-view__header__left"]}>
                <div
                  className={style["article-view__header__left__title"]}
                  dangerouslySetInnerHTML={{
                    __html:
                      article.service === "mediation"
                        ? removeUnwantedTitleStartWords(decodeHtml(article.title))
                        : articleContent?.title
                  }}
                />
                {articleContent.editorialInfo && (
                  <div className={style["article-view__header__left__editorial-info"]}>
                    <strong>PRIV</strong>:{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(articleContent.editorialInfo)
                      }}
                    />
                  </div>
                )}

                {!isCalendarEvent && (
                  <div className={style["article-view__header__left__article-details"]}>
                    <ul>
                      <li className={style["article-view__header__left__article-details__byline"]}>
                        {articleContent.byline}
                      </li>
                    </ul>
                    <ul>
                      <li
                        className={style["article-view__header__left__article-details__version"]}
                        dangerouslySetInnerHTML={{
                          __html: t("articleView:details:version", {
                            versionNumber: parseInt(articleContent.ntbVersion) + 1
                          })
                        }}
                      />
                    </ul>
                    <ul>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("articleView:details:words", { wordCount })
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html: t("articleView:details:readTime", { readTime })
                        }}
                      />
                    </ul>
                  </div>
                )}
              </div>

              {imageMedia &&
                imageMedia.length === 1 &&
                preferredArticleImagePlacement === "top" && (
                  <div className={style["article-view__header__right"]}>
                    <img
                      alt={imageMedia[0].caption}
                      src={imageMedia[0].url}
                      onClick={previewMedia(imageMedia[0])}
                    />
                    <Caption charLimit={145}>{imageMedia[0].caption}</Caption>
                  </div>
                )}
            </div>
            {imageMedia && imageMedia.length > 1 && preferredArticleImagePlacement === "top" && (
              <Slider mediaList={imageMedia} onItemClicked={previewMedia} />
            )}
            <div className={style["article-view__main-content"]}>
              <div
                className={style["article-view__main-content__article"]}
                ref={articleTextViewRef}
              >
                {!isEmpty(leadText) && (
                  <p
                    className={style["article-view__main-content__article__lead"]}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(leadText) }}
                  />
                )}
                <div
                  className={style["article-view__main-content__article__body"]}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(articleContent.contentMarkup, {
                      ADD_ATTR: ["target"]
                    })
                  }}
                />

                {!isEmpty(calendarInfo) && (
                  <table className={style["article-view__main-content__article__calendar-info"]}>
                    <tbody>
                      {map(calendarInfo, (value, key) => (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}

                {imageMedia &&
                  preferredArticleImagePlacement === "bottom" &&
                  imageMedia.length === 1 && (
                    <>
                      <img
                        alt={imageMedia[0].caption}
                        src={imageMedia[0].url}
                        onClick={previewMedia(imageMedia[0])}
                      />
                      <Caption charLimit={220}>{imageMedia[0].caption}</Caption>
                    </>
                  )}

                {otherMedia.length > 0 && (
                  <div
                    className={classNames(
                      style["article-view__extra"],
                      style["article-view__extra--1-column"]
                    )}
                  >
                    <h3 className={style["article-view__extra__title"]}>
                      {t("articleView:otherMediaContent:title")}
                    </h3>
                    <Slider mediaList={otherMedia} onItemClicked={previewMedia} />
                  </div>
                )}
              </div>
              {!isCalendarEvent && (
                <div className={style["article-view__main-content__article-details"]}>
                  <ul>
                    <li
                      className={style["article-view__main-content__article-details__byline"]}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(articleContent.byline)
                      }}
                    />
                    <li
                      className={style["article-view__main-content__article-details__version"]}
                      dangerouslySetInnerHTML={{
                        __html: t("articleView:details:version", {
                          versionNumber: parseInt(articleContent.ntbVersion) + 1
                        })
                      }}
                    />
                    <li>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("articleView:details:words", { wordCount })
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("articleView:details:readTime", { readTime })
                        }}
                      />
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {imageMedia && preferredArticleImagePlacement === "bottom" && imageMedia.length > 1 && (
              <Slider mediaList={imageMedia} onItemClicked={previewMedia} />
            )}

            <div
              className={classNames(
                style["article-view__extra"],
                style["article-view__extra--1-column"]
              )}
            >
              {versions.length > 1 && (
                <div>
                  <h3 className={style["article-view__extra__title"]}>
                    {t("articleView:versions:title")}
                  </h3>
                  <ul className={style["article-view__extra__timeline"]}>
                    {versions.slice(0, versionsLimit).map((version, index) => (
                      <li
                        className={classNames(style["article-view__extra__timeline__item"], {
                          [style["article-view__extra__timeline__item--current"]]:
                            version.id === article.id
                        })}
                        key={version.id}
                        onClick={() => handleVersionClick(version)}
                      >
                        <span className={style["article-view__extra__timeline__item__circle"]}>
                          &nbsp;
                        </span>
                        <span className={style["article-view__extra__timeline__item__date-time"]}>
                          {DateTime.fromMillis(version.time).toFormat("dd.LL.yyyy – 'kl.' HH.mm")}
                        </span>
                        {index === 0 && (
                          <span className={style["article-view__extra__timeline__item__tag"]}>
                            {t("articleView:versions:latest")}
                          </span>
                        )}
                        <span className={style["article-view__extra__timeline__item__title"]}>
                          {version.title}
                        </span>
                      </li>
                    ))}
                  </ul>
                  {versions.length > versionsLimit ? (
                    <div
                      className={classNames(
                        style["article-view__extra__limit-switcher"],
                        style["article-view__extra__limit-switcher--with-icon"]
                      )}
                      onClick={() => setVersionsLimit(versions.length)}
                    >
                      <VerticalEllipsisIcon />
                      {t("articleView:versions:seeAll")}
                    </div>
                  ) : versionsLimit > initialVersionsLimit ? (
                    <div
                      className={classNames(
                        style["article-view__extra__limit-switcher"],
                        style["article-view__extra__limit-switcher--with-icon"]
                      )}
                      onClick={() => setVersionsLimit(initialVersionsLimit)}
                    >
                      <VerticalEllipsisIcon />
                      {t("articleView:versions:seeLess")}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div
              className={classNames(
                style["article-view__extra"],
                style["article-view__extra--2-column"]
              )}
            >
              {relatedArticles.length > 0 && (
                <div>
                  <h3 className={style["article-view__extra__title"]}>
                    {t("articleView:relatedArticles:title")}
                  </h3>
                  <ul className={style["article-view__extra__list"]}>
                    {relatedArticles.slice(0, relatedArticlesLimit).map(relatedArticle => (
                      <li
                        className={style["article-view__extra__list__item"]}
                        key={`related-article-${relatedArticle.id}`}
                      >
                        <a
                          className={style["article-view__extra__list__item__link"]}
                          onClick={() => handleRelatedArticleClick(relatedArticle)}
                        >
                          <div
                            className={style["article-view__extra__list__item__link__timestamp"]}
                          >
                            {DateTime.fromMillis(relatedArticle.time).toFormat(
                              "dd.LL.yyyy – 'kl.' HH.mm"
                            )}
                          </div>
                          {decodeHtml(relatedArticle.title)}
                        </a>
                      </li>
                    ))}
                  </ul>
                  {relatedArticles.length > relatedArticlesLimit ? (
                    <div
                      className={style["article-view__extra__limit-switcher"]}
                      onClick={() => setRelatedArticlesLimit(relatedArticles.length)}
                    >
                      {t("articleView:versions:seeAll")}
                    </div>
                  ) : relatedArticlesLimit > initialRelatedArticlesLimit ? (
                    <div
                      className={style["article-view__extra__limit-switcher"]}
                      onClick={() => setRelatedArticlesLimit(initialRelatedArticlesLimit)}
                    >
                      {t("articleView:versions:seeLess")}
                    </div>
                  ) : null}
                </div>
              )}
              {relatedFacts.length > 0 && (
                <div>
                  <h3 className={style["article-view__extra__title"]}>
                    {t("articleView:relatedFacts:title")}
                  </h3>
                  <ul className={style["article-view__extra__list"]}>
                    {relatedFacts.slice(0, relatedFactsLimit).map(relatedFact => (
                      <li
                        className={style["article-view__extra__list__item"]}
                        key={`related-article-${relatedFact.id}`}
                      >
                        <a
                          className={style["article-view__extra__list__item__link"]}
                          onClick={() => handleRelatedFactClick(relatedFact)}
                        >
                          <div
                            className={style["article-view__extra__list__item__link__timestamp"]}
                          >
                            {DateTime.fromMillis(relatedFact.time).toFormat(
                              "dd.LL.yyyy – 'kl.' HH.mm"
                            )}
                          </div>
                          {relatedFact.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                  {relatedFacts.length > relatedFactsLimit ? (
                    <div
                      className={style["article-view__extra__limit-switcher"]}
                      onClick={() => setRelatedFactsLimit(relatedFacts.length)}
                    >
                      {t("articleView:versions:seeAll")}
                    </div>
                  ) : relatedFactsLimit > initialRelatedFactsLimit ? (
                    <div
                      className={style["article-view__extra__limit-switcher"]}
                      onClick={() => setRelatedFactsLimit(initialRelatedFactsLimit)}
                    >
                      {t("articleView:versions:seeLess")}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div
              className={classNames(
                style["article-view__extra"],
                style["article-view__extra__contact"]
              )}
            >
              <div className={style["article-view__extra__contact__byline"]}>
                {articleContent.byline}
              </div>
              <div>{articleContent.category}</div>
              {articleContent && articleContent.writerEmail && (
                <div className={style["article-view__extra__contact__writer-email"]}>
                  <a href={`mailto:${articleContent.writerEmail}`}>{articleContent.writerEmail}</a>
                </div>
              )}
            </div>
            {articleContent.subjectList && (
              <div
                className={classNames(
                  style["article-view__extra"],
                  style["article-view__extra--1-column"]
                )}
              >
                <h3 className={style["article-view__extra__title"]}>
                  {t("articleView:metaTags:title")}
                </h3>
                <ul className={style["article-view__extra__meta-tags"]}>
                  {articleContent.mediaTopicList.map(mediaTopic => (
                    <Label
                      className={style["article-view__extra__meta-tags__item"]}
                      key={`label-${mediaTopic.name}`}
                      onClick={() => doSearch(mediaTopic.reference)}
                    >
                      <span>{mediaTopic.name}</span>
                    </Label>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <ArticleViewSkeleton />
        )}
      </div>

      <MediaPreview media={previewingMedia} onClose={() => setPreviewingMedia(null)} />
    </div>
  );
}

ArticleView.propTypes = {
  article: PropTypes.object,
  onClose: PropTypes.func,
  latestVersionId: PropTypes.number,
  modal: PropTypes.bool,
  searchString: PropTypes.string
};

export default ArticleView;
